import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-miami-gardens-florida.png'
import image0 from "../../images/cities/scale-model-of-vintage-tours-miami-in-miami-gardens-florida.png"
import image1 from "../../images/cities/scale-model-of-aventura-slide-tower-in-miami-gardens-florida.png"
import image2 from "../../images/cities/scale-model-of-bay-adventures-in-miami-gardens-florida.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Miami Gardens'
            state='Florida'
            image={image}
            lat='25.9420377'
            lon='-80.24560450000001'
            attractions={ [{"name": "Vintage Tours Miami", "vicinity": "2452 NE 184th St, Ojus", "types": ["point_of_interest", "establishment"], "lat": 25.9453797, "lng": -80.15151509999998}, {"name": "Aventura Slide Tower", "vicinity": "19591 Biscayne Blvd, Aventura", "types": ["point_of_interest", "establishment"], "lat": 25.9575208, "lng": -80.14681819999998}, {"name": "Bay Adventures", "vicinity": "1019 NE 104th St, Miami Shores", "types": ["point_of_interest", "establishment"], "lat": 25.8709789, "lng": -80.17671000000001}] }
            attractionImages={ {"Vintage Tours Miami":image0,"Aventura Slide Tower":image1,"Bay Adventures":image2,} }
       />);
  }
}